import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useOutlet } from "react-router-dom";
import "yet-another-react-lightbox/styles.css";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import close from "../../img/Close.svg";
import mask from "../../img/Logo Mask.svg";
import "./index.css";
import Link from "../Link";
import { CartData, countries, ShippingForm, taxRate } from "./utils";
import Space from "../Space";
import Menu from "../Menu";
import { request } from "../../utils";
import Button from "../Button";
import { MerchData } from "../MerchPage/utils";
import Input from "../Input";
import Checkbox from "../Checkbox";
import Select from "../Select";

function CartCard({
  item,
  product,
  setCart,
  index,
}: {
  item: CartData;
  product: MerchData;
  setCart: React.Dispatch<React.SetStateAction<CartData[]>>;
  index: number;
}) {
  const handleRemove = useCallback(() => {
    const cart = JSON.parse(localStorage.getItem("cart") || "[]");
    cart.splice(index, 1);
    localStorage.setItem("cart", JSON.stringify(cart));
    setCart(cart);
  }, [index, setCart]);

  const content = (
    <>
      <img
        src={product.picture}
        className="CartCardCover"
        alt={`${product.name} Product Cover`}
      />
      <div className="CartCardDetails">
        <span className="CartCardName">
          {item.quantity}x {product.name.replace("-", "\u2011")} (
          {
            product.variations.find(
              (variation) => variation.id === item.variation
            )?.name
          }
          )
        </span>
        <Space size="xs" />
        <span className="CartCardPrice">{product.price}</span>
      </div>
      <img
        className="CartRemoveIcon"
        src={close}
        onClick={handleRemove}
        alt="Menu Icon"
      />
    </>
  );

  return <div className="CartCard">{content}</div>;
}

export function CartListScreen() {
  const [products, setProducts] = useState<MerchData[] | null>(null);
  const [cart, setCart] = useState<CartData[]>(
    JSON.parse(localStorage.getItem("cart") || "[]")
  );

  const [submitted, setSubmitted] = useState<boolean | undefined>(false);
  const [error, setError] = useState<string>("");

  const total = useMemo(
    () =>
      cart.reduce(
        (sum, item) =>
          sum +
          parseFloat(
            products?.find((product) => product.id === item.id)?.price || "0"
          ) *
            item.quantity,
        0
      ),
    [cart, products]
  );

  useEffect(() => {
    (async () => {
      try {
        const result = await request("/products");
        setProducts(result.products);
      } catch (e) {
        setProducts([]);
      }
    })();
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (cart.length === 0) return;

      const formData = new FormData(event.currentTarget);
      const values = Object.fromEntries(formData) as ShippingForm;

      (async () => {
        try {
          setSubmitted(undefined);
          const body = JSON.stringify({
            positions: cart.reduce(
              (positions, item) =>
                positions.concat(
                  ...Array.from({ length: item.quantity }).map(() => ({
                    id: item.id,
                    variation: item.variation,
                  }))
                ),
              [] as { id: number; variation: number }[]
            ),
            firstName: values.fname,
            lastName: values.lname,
            email: values.email,
            phone: values.phone,
            address: values["address-one"],
            city: values.city,
            postalCode: values.zip,
            country: values.country,
          });

          const result = await request("/buy", { method: "POST", body });

          if (result.url) {
            localStorage.setItem("cart", "[]");
            setSubmitted(true);
            window.location.href = result.url;
          } else if (result.success) {
            localStorage.setItem("cart", "[]");
            setSubmitted(true);
          } else {
            setSubmitted(false);
            setError(result.error);
            setTimeout(setError, 3000, "");
          }
        } catch (error) {
          setSubmitted(false);
        }
      })();
    },
    [cart]
  );

  return (
    <>
      <Menu>
        <h1 className="CartListTitle">Cart</h1>
      </Menu>

      <div className="CartListScreen">
        {!products && (
          <>
            <Space size="xl" />
            <span className="CartListInfo">Loading cart...</span>
          </>
        )}
        {products && cart.length === 0 && (
          <>
            <Space size="xl" />
            <span className="CartListInfo">Cart is empty.</span>
          </>
        )}
        {products &&
          cart &&
          cart.map((item, index) => {
            const product = products.find((product) => product.id === item.id);
            return (
              product && (
                <Fragment key={index}>
                  <Space size="l" />
                  <CartCard
                    product={product}
                    item={item}
                    setCart={setCart}
                    index={index}
                  />
                </Fragment>
              )
            );
          })}

        {cart.length === 0 ? (
          <>
            <Space size="xl" />
            <Link variant="primary" to="/merch" className="CartCheckoutButton">
              Get merch
            </Link>
          </>
        ) : (
          <form onSubmit={handleSubmit} className="CartForm">
            <Space size="m" />
            <span className="CartVatPrice">
              Incl. 25% VAT: {(total * taxRate).toFixed(0)} SEK
            </span>
            <Space size="xs" />
            <span className="CartTotalPrice">
              Total: {total.toFixed(0)} SEK
            </span>

            <Space size="l" />
            <Button
              variant={submitted || error ? "tertiary" : "primary"}
              disabled={submitted !== false || !!error}
              className="CartCheckoutButton"
              type="submit"
            >
              {error
                ? error
                : submitted === undefined
                ? "Checking out..."
                : submitted
                ? "Redirecting..."
                : "Checkout"}
            </Button>
            <Space size="xxl" />
            <h2 className="CartTitle">Contact details</h2>
            <Space size="l" />

            <div className="CartOptions">
              <Input
                placeholder="first name"
                name="fname"
                id="fname"
                autoComplete="given-name"
                required
              />
              <Space size="s" />
              <Input
                placeholder="last name"
                name="lname"
                id="lname"
                autoComplete="family-name"
                required
              />
              <Space size="s" />
              <Input
                placeholder="email"
                name="email"
                id="email"
                type="email"
                autoComplete="email"
                required
              />
              <Space size="s" />
              <Input
                placeholder="phone number"
                name="phone"
                id="phone"
                type="tel"
                autoComplete="tel"
                required
                pattern="\+\d+"
                title="Format: +46123456789"
              />
            </div>
            <Space size="xxl" />
            <h2 className="CartTitle">Shipping details</h2>
            <Space size="l" />

            <div className="CartOptions">
              <Input
                placeholder="address"
                id="address-one"
                name="address-one"
                autoComplete="address-line1"
                required
              />
              <Space size="s" />
              <Input
                placeholder="city"
                id="city"
                name="city"
                required
                autoComplete="address-level2"
              />
              <Space size="s" />
              <Input
                placeholder="postal code"
                id="zip"
                name="zip"
                autoComplete="postal-code"
              />
              <Space size="l" />
              <Select
                emptyText="Country"
                options={countries}
                id="country"
                name="country"
                autoComplete="country"
                required
              />
              <Space size="l" />
              <Checkbox name="terms" required>
                I agree to the{" "}
                <Link
                  to="https://stockholmnoir.com/StockholmNoir_TermsAndConditions.pdf"
                  target="_blank"
                >
                  Terms and Conditions
                </Link>
              </Checkbox>
            </div>
            <Space size="l" />
          </form>
        )}
      </div>
    </>
  );
}

export default function CartPage() {
  const location = useLocation();
  const nodeRef = useRef(null);
  const currentOutlef = useOutlet();

  return (
    <div className="CartPage" data-pathname={location.pathname}>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={400}
          classNames="CartContent"
          unmountOnExit
        >
          {() => (
            <main ref={nodeRef} className="CartContent">
              {currentOutlef}
            </main>
          )}
        </CSSTransition>
      </SwitchTransition>

      <footer className="EventsFooter">
        <Link to="/live" variant="none">
          <img src={mask} className="EventsLogo" alt="Stockholm Noir Mask" />
        </Link>
      </footer>
    </div>
  );
}
