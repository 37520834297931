import { useState } from "react";
import { LinkProps, useLocation, useNavigate } from "react-router-dom";
import "../Button/index.css";
import "./index.css";
import { useAuth } from "../../auth";
import close from "../../img/Close.svg";
import menu from "../../img/Menu.svg";
import Link from "../Link";
import cx from "classnames";

function MenuLink({
  children,
  setVisible,
  to,
  onClick,
  ...props
}: React.PropsWithChildren<LinkProps> & {
  setVisible: (visible: boolean) => void;
}) {
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(event);
    }

    if (location.pathname === to) {
      setVisible(false);
    }
  };

  return (
    <Link
      variant="none"
      className="MenuLink"
      to={to}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Link>
  );
}

export default function Menu({ children, ...props }: React.PropsWithChildren) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const handleToggle = () => {
    setVisible(!visible);
  };

  const handleLogout = () => {
    auth.signout(() => {
      navigate("/");
    });
  };

  let content = children;

  if (visible) {
    content = (
      <>
        <MenuLink key="/live" to="/live" setVisible={setVisible}>
          Events
        </MenuLink>
        <MenuLink key="/merch" to="/merch" setVisible={setVisible}>
          Merch
        </MenuLink>
        <MenuLink key="/cart" to="/cart" setVisible={setVisible}>
          Cart
        </MenuLink>
        <MenuLink to="/" setVisible={setVisible} onClick={handleLogout}>
          Log out
        </MenuLink>
      </>
    );
  }

  return (
    <header className={cx("MenuHeader", visible && "MenuVisible")} {...props}>
      {content}
      <img
        className="MenuIcon"
        src={visible ? close : menu}
        onClick={handleToggle}
        alt="Menu Icon"
      />
    </header>
  );
}
